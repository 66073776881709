import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { HTTP_IN_ACTIVE } from "../../../constants";
import { useAttendance } from "../Hooks/useAttendance";
import { useTimeEvent } from "../Hooks/useTimeEvent";
import EventModal from "./EventModal";

function Trending(props) {
  /*  Trending component used for Render Trend and Active Events 
 | khaled Mofeed */

  // const { setLoading, loading } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [eventModal, setEventModal] = useState({});
  const { TimeEvent, AllEvent, LiveState } = useTimeEvent();
  const { loading, Attend } = useAttendance();
  /**
   * handleModalClick, function used for view the data of event selected by modal
   * author Khaled Mofeed
   */
  const handleModalClick = (e) => {
    setModalShow(true);
    const EventId = AllEvent?.find(
      (AllEventDetails) => AllEventDetails?.id == e.target.id
    );
    setEventModal(EventId);
  };

  return (
    <>
      <Col md={12} xl={12} className="TrendingSection">
        <Row>
          <Col md={12}>
            <h6>TRENDING</h6>
          </Col>
          {AllEvent?.sort(
            (a, b) => b.attendances_count - a.attendances_count
          ).map(
            (AllEventDetails, index) =>
              TimeEvent(
                AllEventDetails.start_date,
                AllEventDetails.end_date
              ) !== HTTP_IN_ACTIVE && (
                // TimeEvent(
                //   AllEventDetails.start_date,
                //   AllEventDetails.end_date
                // ) !== LiveState &&
                <Col md={6} xl={4} className="viewItem">
                  <div className="containerTrend">
                    <Row>
                      <Col md={4} className="pr-7px">
                        <img
                          src={AllEventDetails.cover}
                          alt="TrendImg"
                          className="TrendImg w-100 pointer"
                          id={AllEventDetails?.id}
                          onClick={handleModalClick}
                        />
                        {AllEventDetails.attendances_count ? (
                          <span className="LableSale">
                            {" "}
                            + {AllEventDetails.attendances_count}{" "}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col lg={12} className="pt-3 pb-2 col-lg-12 px-8-22">
                            <Row>
                              <Col xs={6}>
                                <div className="SatetAt">
                                  {TimeEvent(
                                    AllEventDetails.start_date,
                                    AllEventDetails.end_date,
                                    AllEventDetails.start_time
                                  )}
                                </div>
                              </Col>
                              <Col xs={6}>
                                <div className="actionLable">
                                  <span className="lable-dark">
                                    <img
                                      src={`${process.env.REACT_APP_ASSETS}/img/events/location-light.svg`}
                                      alt="location"
                                    />{" "}
                                    {AllEventDetails.location.substring(0, 5) +
                                      "..."}
                                  </span>
                                  <span className="lable-LightBtn">
                                    <img
                                      src={`${process.env.REACT_APP_ASSETS}/img/events/LightBtn.svg`}
                                      alt="location"
                                    />
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={12} className="py-2 pr-8-22">
                            <div className="titleAndDesc">
                              <h4>{AllEventDetails.name} </h4>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )
          )}
        </Row>
      </Col>
      <EventModal
        key={eventModal?.id}
        show={modalShow}
        onHide={() => setModalShow(false)}
        eventModal={eventModal}
        TimeEvent={TimeEvent}
        Attend={Attend}
        loading={loading}
      />
    </>
  );
}

export default Trending;
