import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import DateTimePicker from "react-datetime-picker";
import { AppContext } from "../../../scripts/context";
import { useTimeEvent } from "../Hooks/useTimeEvent";
import { locationArr } from "../../../configs/configLocationFillter";
import { CLOSE_ICON } from "../../../constants";

function Filter(props) {
  /*  Filter Component,  filters the data and returns it in the AllEvents Component
 | khaled Mofeed */
  const {
    setCategoriesId,
    CategoriesId,
    location,
    setLocation,
    setDate_From,
    setDate_To,
    keyword,
    setKeyword,
    setHistory,
    setOwned,
  } = useContext(AppContext);
  /**
   * use the useTimeEvent custom hook to handle the operations
   * author Khaled Mofeed
   */
  const { AllEventCategories } = useTimeEvent();
  const [selectedDateFrom, setSelectedDateFrom] = useState();
  const [selectedDateTo, setSelectedDateTo] = useState();
  /**
   * handleDate Function use to formate the date
   * author Khaled Mofeed
   */

  const handleDate = (date, type) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const handlers = [
      {
        title: "DateFrom",
        handler: (date) => {
          setSelectedDateFrom(date);
          setDate_From(
            `${year}-${month.toString().padStart(2, "0")}-${day
              .toString()
              .padStart(2, "0")}`
          );
        },
      },
      {
        title: "DateTo",
        handler: (date) => {
          setSelectedDateTo(date);
          setDate_To(
            `${year}-${month.toString().padStart(2, "0")}-${day
              .toString()
              .padStart(2, "0")}`
          );
        },
      },
    ];
    handlers.forEach((handler) => {
      if (handler.title === type) {
        handler.handler(date);
      }
    });
  };
  /**
   * handleResetDate Function use to Reset or clear the value of date that selected
   * author Khaled Mofeed
   */
  const handleResetDate = (type) => {
    if (type === "DateFrom") {
      setSelectedDateFrom();
      setDate_From();
    } else if (type === "DateTo") {
      setSelectedDateTo();
      setDate_To();
    }
  };

  return (
    <Col md={4} xl={4} className="TrendingSection CategoriesFillter">
      <div className=" mb-4 col-12 col-md-12">
        <div className="row">
          <h6 className="nickName mt-2 mb-4 col-4">Fillters</h6>
          <FormGroup className="col-8 OwnedStyle">
            <FormControlLabel
              label="My Events"
              control={
                <Switch
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOwned("1");
                    } else {
                      setOwned("0");
                    }
                  }}
                />
              }
            />
          </FormGroup>
        </div>
      </div>

      <div className=" mb-4 col-12 col-md-12">
        <div className=" SearchCustomInput input-group bg-border-gradiant contact-form-bg">
          <span className="input-group-text" id="basic-addon1">
            <img
              src={`${process.env.REACT_APP_ASSETS}/img/search.svg`}
              alt="searchIcon"
              className="searchIcon"
            />
          </span>
          <input
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Search By Name / Owner"
            aria-label="Username"
            aria-describedby="basic-addon1"
            className="form-control form-control-solid border-0 bg-dark-2 "
            type="search"
          />
        </div>
      </div>
      <h6 className="nickName borderTopPadding mb-4">Categories</h6>
      <div className="form-group col-12 col-md-12 mb-2">
        <div className="row px-3">
          <div className="col-12">
            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              variant="standard"
              className="m-0 columns_2"
            >
              {AllEventCategories?.map((Categories, index) => {
                return (
                  <FormControlLabel
                    className="CategoriesInput"
                    key={Categories.id}
                    label={Categories.name}
                    sx={{
                      width: "fit-content",
                    }}
                    control={
                      <Checkbox
                        type="checkbox"
                        className="CheckboxCategories"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCategoriesId([...CategoriesId, Categories.id]);
                          } else {
                            setCategoriesId(
                              CategoriesId.filter(
                                (val) => val !== Categories.id
                              )
                            );
                          }
                        }}
                      />
                    }
                  />
                );
              })}
            </FormControl>
          </div>
        </div>
      </div>
      <h6 className="nickName borderTopPadding mb-4">Location</h6>
      <div className="form-group col-12 col-md-12 mb-2">
        <div className="row px-3">
          <div className="col-12">
            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              variant="standard"
              className="m-0 columns_2"
            >
              {locationArr?.map((Location, index) => {
                return (
                  <FormControlLabel
                    className="CategoriesInput"
                    key={Location.value}
                    label={Location.name}
                    sx={{
                      width: "fit-content",
                    }}
                    control={
                      <Checkbox
                        type="checkbox"
                        className="CheckboxCategories"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setLocation([...location, Location.value]);
                          } else {
                            setLocation(
                              location.filter((val) => val !== Location.value)
                            );
                          }
                        }}
                      />
                    }
                  />
                );
              })}
            </FormControl>
          </div>
        </div>
      </div>

      <h6 className="nickName borderTopPadding mb-4">The Time Range</h6>
      <div className="form-group col-12 col-md-12 mb-2 calender">
        <div className="row px-3">
          <div className="form-group col-12 col-md-6 mb-0 position-relative">
            <label className="basic-url">From</label>
            <DateTimePicker
              className="w-100"
              onChange={(date) => {
                if (date) {
                  handleDate(date, "DateFrom");
                }
              }}
              value={selectedDateFrom}
              maxDate={selectedDateTo}
              clearIcon={false}
              calendarClassName="Selectdatetime"
              locale="English"
              format="y-MM-dd"
            />
            <img
              src={CLOSE_ICON}
              onClick={() => handleResetDate("DateFrom")}
              alt="close"
              className="resetDateImg"
            />
          </div>
          <div className="form-group col-12 col-md-6 mb-0 position-relative">
            <label className="basic-url">To</label>
            <DateTimePicker
              className="w-100"
              onChange={(date) => {
                if (date) {
                  handleDate(date, "DateTo");
                }
              }}
              value={selectedDateTo}
              minDate={selectedDateFrom}
              clearIcon={false}
              calendarClassName="Selectdatetime"
              locale="English"
              format="y-MM-dd"
            />

            <img
              src={CLOSE_ICON}
              onClick={() => handleResetDate("DateTo")}
              alt="close"
              className="resetDateImg"
            />
          </div>
        </div>
      </div>

      <h6 className="nickName borderTopPadding mb-4">Archives</h6>
      <div className="form-group col-12 col-md-12 mb-2">
        <div className="row px-3">
          <div className="col-12">
            <FormControl
              sx={{ m: 3 }}
              component="fieldset"
              variant="standard"
              className="m-0"
            >
              <FormControlLabel
                className="CategoriesInput"
                label="View Ended Events"
                sx={{
                  width: "fit-content",
                }}
                control={
                  <Checkbox
                    type="checkbox"
                    className="CheckboxCategories"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setHistory("1");
                      } else {
                        setHistory("0");
                      }
                    }}
                  />
                }
              />
            </FormControl>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default Filter;
